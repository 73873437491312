.side-nav {
    display:flex;
    flex-direction:column;
    height:100vh;
    width: 295px;

    margin-right:0;
}

.side-nav-direct-cont {
    display:flex;
    flex-direction:column;
    margin-left:5px;
    margin-top: 20px;
}

.side-nav-direct {
    padding: 10px 10px 8px;
}
.side-nav-direct:hover {
    background-color:rgb(240, 240, 240);
    cursor:pointer;
    display:flex;
    flex-wrap:initial;
}


.side-nav-direct-icon {
    height: 35px;
    display: inline-block;
    margin-right: 20px;
}

.side-nav-direct-title {
    display: inline-block;
    text-align:center;
    color:black;
    font-weight:bold;
    font-size: 22px;
}

.side-nav-direct-title-clicked {
    display: inline-block;
    text-align:center;
    color:rgba(255,0,0,255);
    font-weight:bold;
    font-size: 23px;
}

.cat-title {
    margin: 30px 10px 15px;
    font-size:23px;
    font-weight:bold;
    color:rgb(0, 0, 0);
}

.cat-container {
    margin: 10px 10px 10px;
}

.sidenav-cat {
    display:inline-block;
    font-size: 18px;
    margin-bottom:15px;
    margin-right:10px;
    color: black;
    text-decoration: none;
}

.sidenav-cat:hover {
    color:red;
    transition-duration:all 0.1s ease;
    text-decoration:underline;
}


@media (max-width:1300px) {
    .side-nav-direct > p, .cat-title, .cat-container {
        display: none;
    }

    .side-nav {
        width: 100px;
    }
}

.dropdown-button {
    background-color:red;
    height:15px;
    margin-left:10px;
    margin-bottom:20px;
    flex-wrap:wrap;
}

.hidden {
    display:none;
}

.dropdown-format {
    margin-left:25px;
    font-weight:bold;
    list-style: none;
    font-size:20px
}