

.login-page {
    position:fixed;
    display:flex;
    flex-direction:column;
    z-index:100;
    
    top:50%;
    left:50%;
    transform:translate(-50%, -53%);

    padding: 20px 30px 45px;

    background-color:white;
    border-radius:8px;
    margin-top: 50px;
    align-items:center;
    justify-content:center;

    box-shadow: 0 0 20px 5px rgb(59, 59, 59);

}
.login-page-title {
    font-weight:bold;
    font-size:35px;
    letter-spacing:1px;
    align-self:flex-start;
    margin: 0;
}
.login-page-minortext {
    align-self:flex-start;
    color:rgb(117, 117, 117);
    margin-top:-2px;
    margin-bottom:25px;
    font-size:15px;
}
.login-page-signuplink {
    color:red;
    text-decoration: underline;
    margin-left: 5px;
    cursor:pointer;
    font-size:17px;
}
.login-page-signuplink:hover {
    color: rgb(207, 40, 68);
}
/*'signup' represents classes for both login and signup */
.signup-form {
    display:flex;
    flex-direction:column;

    background-color:white;
    color:black;
    width: 400px;
    border-radius:20px;
}
.signup-field {
    margin-bottom:20px;
    height:50px;
    border-radius:8px;
    border: 1px solid grey;
    padding: 15px 20px 15px;
    font-size:15px;
}

.signup-label {
    color:rgb(41, 41, 41);
    font-size:16px;
    font-weight:bold;
    letter-spacing: 1px;
    margin-bottom:3px;
}


/*Checkbox portion*/
.signup-checkbox-wrapper {
    display:flex;
    align-items:center;
    gap: 5px;
}
input[type="checkbox"] {
    color:red;
    height:17px;
    width:17px;
    border-color: rgb(117, 117, 117);
}

.signup-checkbox-text {
    font-size:14px;
    color:rgb(117, 117, 117);
}




.signup-submitbtn {
    height:50px;
    border:none;
    color:white;
    background-color:red;

    font-weight:bold;
    font-size:13px;
    letter-spacing: 1px;

    border-radius:6px;
    margin-top:30px;
}
.signup-submitbtn:hover {
    background-color:rgb(207, 40, 68);
    transition-duration:0.2s;
}
.signup-submitbtn:active {
    background-color:red;
}

.signup-errormsg {
    font-size:14px;
    letter-spacing:0px;
    margin-left: 5px;
    color:rgb(255, 0, 0) !important;
    font-weight: normal;
}


/*'or login/signup with' components */
.signup-orsignupwith {
    display: flex;
    margin-top: 20px;
    width:100%;
    color:rgb(167, 167, 167);
}
.signup-separator {
    flex:1;
    border-top:1px solid black;
}
.signup-separator-text {
    margin: 0 15px 0;
}



/*google and fb buttons */
.signup-googlefb-btns {
    display:flex;
    width:100%;
    justify-content:center;
    gap:20px;
    margin-top:20px;
}
.signup-googlebtn {
    display:flex;
    background-color:white;
    border: 1px solid rgb(211, 49, 0);
    border-radius:8px;
    color: rgb(211, 49, 0);
    font-weight:bold;
    font-size:15px;

    width:43%;
    padding:10px;


    justify-content:center;
    gap:20px;
    align-items: center;
}
.signup-googlebtn:hover {
    background-color:rgb(255, 223, 211)
}
.signup-googlebtn:active {
    background-color:rgb(255, 170, 139)
}
.signup-googlefb-text {
    text-align:center;
    align-self:center;
    margin:0;
}
.signup-googlelogo {
    height:20px;
}

.signup-fbbtn {
    display:flex;
    background-color:white;
    border: 1px solid rgb(60, 90, 154);
    border-radius:8px;
    color: rgb(60, 90, 154);
    font-size:15px;
    font-weight:bold;

    width:43%;
    padding:10px;


    justify-content:center;
    gap: 20px;
    align-items: center;
}
.signup-fbbtn:hover {
    background-color:rgb(209, 223, 255)
}
.signup-fbbtn:active {
    background-color:rgb(139, 174, 255)
}
.signup-fblogo {
    height:20px;
}






/*Exists within App.js, makes the background dark */
.login-overlay {
    background-color: rgba(0,0,0,0.42);
    position:fixed;
    left:0;
    top: 0;
    width:100%;
    height:100%;
  }


