.logo-name {
    display:flex;
    align-items:center;

    margin-left: 5px;
    margin-bottom: 4px;
    height:100%;
    text-decoration:none;
}

.nav-logo {
    height: 80%;
}


.topnav-name {
    color:black;
    font-weight: bold;
    text-align:center;
    align-self:center;
    font-size: 30px;
    
    margin: 0 0 0 10px;
    padding:0;
}



/*Navbar: */
.topnav {
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
    margin-top: 10px;
    padding-left:10px;
    align-items: center;
    padding-bottom: 5px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
}

@media only screen and (max-width:640px) {
    .logo-name > p{
        display: none;
    }
}







/* searchbar*/
.topnav-searchbar {
    display:flex;
    flex-grow:1;
    border: none;
    height: 65%;
    border-radius: 25px;
    min-width:150px;
    max-width:400px;
    margin-right:10px;
    flex: 0.7;
    padding: 5px 15px 5px;

    background-image: linear-gradient(rgb(241, 241, 241), rgb(224, 224, 224));
    box-shadow: 0 0 2px 0px rgb(158, 158, 158);
}

.searchbar {
    display: inline-block;
    align-self: center;
    flex-grow:1;
    height: 100%;
    border-radius: 10px;
    border: none;
    margin-right: 5px;
    margin-left: 15px;
    min-width:0;
    font-size:15px;

    background-color:transparent;
}
.searchbar:focus {
    outline: none;
}
.searchbar::placeholder {
    color:rgb(78, 78, 78);
    font-size:17px;
}

.search-icon {
    height: 60%;
    padding-bottom:3px;
    padding-left:3px;
}
.search-icon:hover {
    height:65%;
}

.nav-searchbutton {
    height: 80%;
    margin-top: 4px;
    border:none;
    background-color: transparent;
    border-left: 1px solid rgb(175, 174, 174);
    margin-right: 10px;
}









.login-and-threedot {
    display:flex;
    align-self:flex-end;
    margin-right: 25px;
}
.nav-login {
    border: none;
    background-color:var(--lightred-color);
    color: white;
    height: 45px;
    width: 100px;
    margin-bottom:12px;

    align-self:center;
    font-weight:bold;
    text-align:center;
    font-size: 17px;
    border-radius: 5px;
}
.nav-login:hover {
    background-color:var(--darkred-color);
}
.nav-login:active {
    background-color:rgb(245, 58, 89);
}

.dropdown-toggle::after { 
    content: none; 
} 

.dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0; /* remove the gap so it doesn't close */
}

#dropdownMenuButton1 {
    margin-left: 0px;
    margin-bottom :10px;
    border:none;
    background-color:white;
    color:black;
    font-size: 28px;
    font-weight: bold;
}
.dropdown-menu {
    margin-left: -140px;
}