.popcat-page {
    margin-top: auto;
    margin-bottom:auto;
    margin-left: 60px;
    margin-right: 50px;
    padding: 10px 10px 10px;

    border-radius: 30px;
    background-color:rgb(171, 255, 248);
}

.popcat-page-title {
    color:rgb(13, 0, 87);
    margin-left: 15px;
    font-size: 60px;
    font-weight: bold;
    align-self: center;
}

.category {
    padding: 5px 10px 5px;
    margin-top: 20px;
    width: 100%;
}

.category-title {
    margin-left: 10px;
    font-size: 50px;
    font-weight: bold;
    color:rgb(9, 0, 92);
    text-decoration: none !important;
}

.category-title:hover {
    
    margin-top:10px;
    text-decoration: underline;
}

.link {
    text-decoration:none;
    color:black;
    margin-left: 10px;
    margin-top:10px;
}

.video-block {
    display: flex;
    align-items:center;

    height: 650px;
    width: 1200px;

    margin-left: 10px;
    margin-right:10px;
    padding-top:5%;
    padding-right:20px;
    padding-left:20px;
    
    background: rgb(219, 255, 251);
    border-radius: 15px;

}

.video-grid-element {
    display:flex;
    width:280px;
    margin-top:-20px;
    flex-direction:column;
}

.video-grid-container {
    display: inline-grid;
    height:100%;
    width:100%;
    gap: 80px;
    grid-template-columns: 250px 250px 250px;
    margin-left:50px;
    margin-top:10px;
  }

  .testing-test {
    color:blue;
    z-index:-1;
  }



.video-title-container {
    height:130px;
    width:100%;
    padding: 10px 10px 30px 10px;
    margin-top:-5px;
    margin-bottom:initial;

    border-bottom-left-radius:15px;
    border-bottom-right-radius:15px;
    display:flex;
    flex-wrap:wrap;

}

.video-title-username-flexbox {
    display:flex;
    width:100%;
    justify-content:space-between;
}
.video-title {
    width:100%;
    font-size:17px;
    font-weight:bold;
    margin-bottom:10px;
}

.video-title:hover, .video-username:hover {
    color:rgb(44, 44, 212);
    text-decoration:underline;
    cursor:pointer;
}

.video-username, .video-views {
    font-size:14px;
} 


.cat-arrowbutton-left, .cat-arrowbutton-right {
    align-self: center;
    background-color: rgb(219, 255, 251);
    border: none;
    height: 80px;
    width: 80px;
    margin-top: -50px;
    border-radius: 40px;
}


.cat-arrowbutton-left:hover, .cat-arrowbutton-right:hover {
    background-color: rgb(134, 247, 233);
}

.right-arrow, .left-arrow {
    height: 70%;
}

#video-short-embed {
    margin:0px;
    padding:0px;
    height:500px;
    width:280px;

    border-radius:20px;
    border-top-left-radius:20px;
}
