.cat-page {
    display:flex;
    flex-direction: column;

    margin-left: 30px;
    margin-top: 10px;
}

.catpage-title-container {
    height:300px;
    width: 1300px;
    background:blue;
    color:white;
    display:flex;
    flex-direction: column;

    border-radius: 25px;
}

.catpage-header {
    margin-left:10px;
    font-size:100px;
}

.cat-description {
    margin-left:10px;
    font-size:20px
}

.pop-scholars {
    background: rgb(187, 255, 248);
    height:350px;
    width:910px;
    margin-top:20px;
    padding: 5px 5px 5px;
    display:flex;
    flex-direction:column;

    border-radius: 25px;
}
.pop-scholars-header {
    margin-left:10px;
    color:Black;
}

.pop-scholars-grid {
    display:flex;
    flex-direction:row;
}

.scholar {
    height:250px;
    width:180px;
    margin-left:30px;
    display:flex;
    flex-direction:column;

}

.scholar-image {
    height:200px;
    width:175px;
}

.scholar-image-png {
    height:200px;
    width:175px;

}

.scholar-name-box {
    display:flex;
    height:40px;
    width:175px;
    background:white;
    margin-top:10px;
    display:flex;
    flex-wrap:wrap;

}

.scholar-name {
    font-size:10px;
    margin-left:5px;
    margin-top:2px
}

.circular--landscape { 
    display: inline-block; 
    position: relative; 
    width: 150x; 
    height: 1000px; 
    overflow: hidden; 
    border-radius: 100%; } 
    
.circular--landscape img { 
    width: auto; 
    height: 100%; 
    margin-left: -50px; }

.popschol-tocontents {
    display:flex;
    flex-direction: row;
}

.tocontents {
    height:350px;
    width:380px;
    background:rgb(202, 255, 250);
    margin-top:20px;
    margin-left:10px;

    border-radius: 25px;
    padding: 10px 10px 10px;
}

.toc-header {
    font-size:30px;
    margin-left:5px;
    margin-top:5px;
}

.video-block-homepage {
    background: rgb(219, 255, 251);
    height: 400px;
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    margin-right:10px;
    width: 905px;
    margin-top:10px;

}

.right_arrow-homepage {
    margin-top: 150px;
    height:70px;
    width:40px
}

.left_arrow-homepage {
    margin-top: 150px;
    height:70px;
    width:40px;
    margin-left:10px;
    margin-right:0px;
}

.popauth {
    height:400px;
    width:380px;
    background:rgb(181, 255, 248);
    margin-top:10px;
    display:flex;
    flex-direction: column;
}

.popauth-title {
    font-size:25px;
    margin-left:7px;
    margin-top:5px;
}

.subcat-popauth {
    width:1300px;
    height:500px;
    display:flex;
    flex-direction: column;
    background:white;
}

.circular--landscape-subcat { 
    display: inline-block; 
    position: relative;
    overflow: hidden; 
    border-radius: 100%;
    margin-left:40px;
    margin-right:40px;
    margin-top:10px;
    margin-bottom:10px

} 

.popauth-name-box {
    display:flex;
    height:40px;
    width:200px;
    background:white;
    margin-top:0px;
    margin-bottom:10px;
    margin-left:90px;
    margin-right:20px;
    display:flex;
    flex-wrap:wrap;

}