.videoplayer {
    height: 850px;
    width: 1200px;
    display: flex;
}

.video-container {
    height: 100%;
    width: 60%;
    margin: 0;
}


#video-embed {
    height:100%;
    width:80%;

    margin: 0 10% 0;
}

.abstract-comments-container {
    height:100%;
    width: 55%;
    display:flex;
    flex-direction:column;

}

.abstract {
    height:70%;

    border: 1px solid black;

    margin: 0 0 20px;
}

.vp-comments {
    border: 1px solid black;

    height: 30%;

    font-size:40px;
    font-weight:bold;
}